





























































































































































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import Loader from '@/components/loader.vue'; // @ is an alias to /src
import administration from '@/components/administration.vue'; // @ is an alias to /src
import administrationHeader from '@/components/administrationheader.vue'; // @ is an alias to /src
import BuiltinService from '@/services/buitin-service';
@Component({
  components: {
    Header,
    administration,
    administrationHeader,
    Loader    
  },
})
export default class AssestsBuiltin extends Vue {
  public assets_file = null;
  public bloodgroup_file = null;
  public branch_file = null;
  public department_file = null;
  public designatoin_file = null;
  public education_file = null;
  public employment_file = null;
  public expat_file = null;
  public nationality_file = null;
  public subbranch_file = null;
  public loading = false;
  // retrieve() {
  //   this.loading = true;
  //   BuiltinService.getasset().then((response) => {
  //       this.items = response.data;
  //       this.loading = false;
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }

  handleSubmit(){

    // this.create();
  }

  mounted() {
    // this.retrieve() 
  }
}
